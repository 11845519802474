import React, { useContext, useRef, useState} from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Skeleton } from "primereact/skeleton";
import { Tag } from "primereact/tag";
import { ToggleButton } from 'primereact/togglebutton';
import { MdEdit, MdCheck } from "react-icons/md";
import therest from "../../therest";
import { AuthContext } from "../../AuthContext";
import { TabPanel, TabView } from "primereact/tabview";
import { InputTextarea } from "primereact/inputtextarea";
import { MdOutlineSearch } from 'react-icons/md';
import { Image } from "primereact/image";
import { UserContext } from "../../UserContext";

const Modals = ({type, data, setData, open = false, onHide, onSubmit, accounts, triggerMessage }) => {
  const { apiToken } = useContext(AuthContext);
  const { user } = useContext(UserContext);

  const [seconds, setSeconds] = useState(''); 
  const [otpButton, setOtpButton] = useState(true); 
  const inputReferredBy = useRef(null);
  const [editReferredBy, setEditReferredBy] = useState(false);
  const [referredby, setReferredby] = useState();
  const [timer, setTimer] = useState();
  const [role, setRole] = useState();
  switch(type){
    case 'user':
      const roles = [
        { value: 2, name: 'Member'},
        { value: 3, name: 'Influencer'},
      ];

      const handleRoleChange = async (value, user_id) => {
        if(user.role_id == 1) {
          setRole(value);
          await therest.post('/user/update-user-roles', {user_id: user_id, role_id: value}, apiToken).then((response)=>{
            triggerMessage(response.status, response.message)
          });
          beforeOnHide();
        }
      }

      const handleInputChange = (e) => {
        setReferredby(e.target.value);
      }
      const handleEditReferredBy = (value) => {
        console.log(data);
        if(!value){
          
          onSubmit({referred_by: referredby, user_id: data["user"].id});
          
          setReferredby('');
          setEditReferredBy(false);
          onHide(true);
        } else {
          setReferredby(data["parent"].referral_code);
          inputReferredBy.current.focus();
        }
        setEditReferredBy(value);
      }
      
      const beforeOnHide = () => {
        setReferredby('');
        setEditReferredBy(false);
        onHide();
      }
      
      return(
        <Dialog header="User Details" draggable={false} visible={open} className="lg:w-1/2 w-3/4" baseZIndex={999999} closable={true} dismissableMask={true} onHide={() => beforeOnHide()}>
         
          
          {
            data && (
              <>
                <TabView>
                  <TabPanel header="Profile">
                    <div className="grid lg:grid-cols-4 gap-4 items-end">
                      <div className="lg:col-span-1 text-center">
                        <Avatar className="w-24 h-24" image={data['user'].profile_url||"/person.png"} size="xlarge" shape="circle" />
                      </div>
                      <div className="lg:col-span-1">
                        <div className="flex flex-col gap-1">
                          <label className="font-bold" htmlFor="name">Name</label>
                          <InputText value={data["user"].name} className="w-full ring-0 border rounded-full px-4 py-3" readOnly={true} id="name" aria-describedby="username-help" />
                        </div>
                      </div>
                      <div className="lg:col-span-1">
                        <div className="flex flex-col gap-1">
                          <label className="font-bold" htmlFor="name">My Referral Code (UserID)</label>
                          <InputText value={data["user"].referral_code} className="w-full ring-0 border rounded-full px-4 py-3" readOnly={true} id="name" aria-describedby="username-help" />
                          
                        </div>
                      </div>
                      <div className="lg:col-span-1">
                        <div className="flex flex-col gap-1">
                          <label className="font-bold" htmlFor="referredby">Referred By</label>
                          
                          {/* <input type="text" defaultValue={referredby} onKeyUp={handleInputChange} name="referredby" id="referredby" className="bg-gray-50 border-[1px] border-gray-300 text-gray-900 text-sm rounded-full focus-visible:ring-[1px] focus-visible:border-[1px] block w-full p-4 px-4"/> */}
                          <div className="relative">
                            {/* {(data["user"].status!=1) ?<InputText ref={inputReferredBy} value={referredby} placeholder={data["parent"].referral_code} onChange={handleInputChange} className="w-full ring-0 border rounded-full px-4 py-3" readOnly={!editReferredBy}  id="referredby" aria-describedby="username-help" /> : ''} */}
                            <InputText ref={inputReferredBy} value={referredby} placeholder={data["parent"].referral_code} onChange={handleInputChange} className="w-full ring-0 border rounded-full px-4 py-3" readOnly={!editReferredBy}  id="referredby" aria-describedby="username-help" />
                            
                            {(data["user"].status!=1) ?<ToggleButton onLabel="" offLabel="" onIcon={<MdCheck className="text-BrandGreen"/>} offIcon={<MdEdit className="text-BrandBlue"/>} className="absolute right-0 bg-transparent rounded-full ring-0" checked={editReferredBy} onChange={(e) => handleEditReferredBy(e.value)}/> : ''}
                          </div>
                            
                        </div>
                      </div>
                      <div className="lg:col-span-1">
                        <div className="flex flex-col gap-1">
                          <label className="font-bold" htmlFor="name">Mobile Number</label>
                          <InputText value={data["user"].mobile_number || '---'} className="w-full ring-0 border rounded-full px-4 py-3" readOnly={true} id="name" aria-describedby="username-help" />
                        </div>
                      </div>
                      <div className="lg:col-span-1">
                        <div className="flex flex-col gap-1">
                          <label className="font-bold" htmlFor="name">Birthday</label>
                          <InputText value={moment(data["user"].birthdate).format('MMM DD, YYYY')} className="w-full ring-0 border rounded-full px-4 py-3" readOnly={true} id="name" aria-describedby="username-help" />
                        </div>
                      </div>
                      <div className="lg:col-span-2">
                        <div className="flex flex-col gap-1">
                          <label className="font-bold" htmlFor="name">Email</label>
                          <InputText value={data["user"].email} className="w-full ring-0 border rounded-full px-4 py-3" readOnly={true} id="name" aria-describedby="username-help" />
                        </div>
                      </div>
                      <div className="lg:col-span-3">
                        <div className="flex flex-col gap-1">
                          <label className="font-bold" htmlFor="name">Address</label>
                          <InputText value={data["user"].address || '---'} className="w-full ring-0 border rounded-full px-4 py-3" readOnly={true} id="name" aria-describedby="username-help" />
                        </div>
                      </div>
                      <div className="lg:col-span-1">
                        <div className="flex flex-col gap-1">
                          <label className="font-bold" htmlFor="name">City</label>
                          <InputText value={data["user"].city || '---'} className="w-full ring-0 border rounded-full px-4 py-3" readOnly={true} id="name" aria-describedby="username-help" />
                        </div>
                      </div>
                      <div className="lg:col-span-1">
                        <div className="flex flex-col gap-1">
                          <label className="font-bold" htmlFor="name">Zip Code</label>
                          <InputText value={data["user"].zipcode || '---'} className="w-full ring-0 border rounded-full px-4 py-3" readOnly={true} id="name" aria-describedby="username-help" />
                        </div>
                      </div>
                      <div className="lg:col-span-1">
                        <div className="flex flex-col gap-1">
                          <label className="font-bold" htmlFor="name">Nationality</label>
                          <InputText value={data["user"].nationality || '---'} className="w-full ring-0 border rounded-full px-4 py-3" readOnly={true} id="name" aria-describedby="username-help" />
                        </div>
                      </div>
                      <div className="lg:col-span-1">
                        <div className="flex flex-col gap-1">
                          <label className="font-bold" htmlFor="name">Role</label>
                          <InputText value={data['user'].role_id && roles.filter((d) => d.value === data['user'].role_id)[0].name || '---'} className="w-full ring-0 border rounded-full px-4 py-3" readOnly={true} id="name" aria-describedby="username-help" />
                          {/* <Dropdown readOnly={true} disabled={true} value={role || data['user'].role_id} 
                            onChange={(e) => handleRoleChange(e.value, data['user'].id) } 
                            className="w-full ring-0 border rounded-full px-4" 
                            options={roles} 
                            optionLabel="name" 
                            placeholder="Select Role" 
                          /> */}
                        </div>
                      </div>
                      <div className="lg:col-span-1">
                        <div className="flex flex-col gap-1">
                          <label className="font-bold" htmlFor="name">Status</label>
                          <Tag className={`${(data["user"].status == 3) ? 'bg-gray-500':''} px-4 py-4`} severity={data["user"].status == 1 ? 'success' : (data["user"].status == 2 ? 'warning' : 'danger')} value={(data["user"].status==1 ?'Active':(data["user"].status==2 ?'Pending':'Inactive'))} rounded />
                        </div>
                      </div>
                      
                    </div>

                  </TabPanel>

                  <TabPanel header="Payment Details">
                    <div className="grid lg:grid-cols-4 gap-4">
                      <div className="lg:col-span-2">
                        <div className="flex flex-col gap-1">
                          <label className="font-bold">Date Registered</label>
                          <InputText value={data["payment_details"] && moment(data["payment_details"].created_at).format('MMM DD, YYYY hh:mm A') || '---'} className="w-full ring-0 border rounded-full px-4 py-3" readOnly={true}/>
                        </div>
                      </div>
                      <div className="lg:col-span-2">
                        <div className="flex flex-col gap-1">
                          <label className="font-bold">Payment Date</label>
                          <InputText value={data["payment_details"] && data["payment_details"].transaction && moment(data["payment_details"].transaction.created_at).format('MMM DD, YYYY hh:mm A') || '---'} className="w-full ring-0 border rounded-full px-4 py-3" readOnly={true}/>
                        </div>
                      </div>
                      <div className="lg:col-span-2">
                        <div className="flex flex-col gap-1">
                          <label className="font-bold">Processed By</label>
                          <InputText value={(data["payment_details"] && data["payment_details"].processed_by_user && data["payment_details"].processed_by_user.name) || '---'} className="w-full ring-0 border rounded-full px-4 py-3" readOnly={true}/>
                        </div>
                      </div>

                      <div className="lg:col-span-1">
                        <div className="flex flex-col gap-1">
                          <label className="font-bold">Mode of Payment</label>
                          <InputText value={data["payment_details"] && data["payment_details"].transaction && data["payment_details"].transaction.mode_of_payment.name || '---'} className="w-full ring-0 border rounded-full px-4 py-3" readOnly={true}/>
                        </div>
                      </div>

                      <div className="lg:col-span-1">
                        <div className="flex flex-col gap-1">
                          <label className="font-bold">Amount</label>
                          <InputText value={data["payment_details"] && data["payment_details"].transaction && Number(data["payment_details"].transaction.amount).toFixed(2) || '---'} className="w-full ring-0 border rounded-full px-4 py-3" readOnly={true}/>
                        </div>
                      </div>

                      <div className="lg:col-span-2">
                        <div className="flex flex-col gap-1">
                          <label className="font-bold">Remarks</label>
                          <InputTextarea value={data["payment_details"] && data["payment_details"].transaction && data["payment_details"].transaction.remarks || '---'} className="w-full ring-0 border rounded-full px-4 py-3" readOnly={true}/>
                        </div>
                      </div>

                      <div className="lg:col-span-2">
                        <div className="flex flex-col gap-1">
                          <label className="font-bold">Proof of Payment</label>
                          {
                            data["payment_details"] && data["payment_details"].transaction && data["payment_details"].transaction.proof_url && (
                              <Image pt={{ mask: { className: 'text-white' } }} imageClassName="rounded-lg" downloadable={true} src={data["user"].proof_url} indicatorIcon={<MdOutlineSearch/>} alt="Image" preview />
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </TabView>
                
              </>
            )
          }
          <div className="grid lg:grid-cols-4 gap-4">
            {
              !data && (
                <>
                  <div className="lg:col-span-1 text-center">
                    <Skeleton shape="circle" size="5rem"></Skeleton>
                  </div>
                  <div className="lg:col-span-2">
                    <div className="flex flex-col gap-1">
                      <Skeleton width="10rem"></Skeleton>
                      <Skeleton height="2rem" className="px-4 py-3" borderRadius="16px"></Skeleton>
                    </div>
                  </div>
                  <div className="lg:col-span-1">
                    <div className="flex flex-col gap-1">
                      <Skeleton width="10rem"></Skeleton>
                      <Skeleton height="2rem" className="px-4 py-3" borderRadius="16px"></Skeleton>
                    </div>
                  </div>
                  <div className="lg:col-span-1">
                    <div className="flex flex-col gap-1">
                      <Skeleton width="10rem"></Skeleton>
                      <Skeleton height="2rem" className="px-4 py-3" borderRadius="16px"></Skeleton>
                    </div>
                  </div>
                  <div className="lg:col-span-1">
                    <div className="flex flex-col gap-1">
                      <Skeleton width="10rem"></Skeleton>
                      <Skeleton height="2rem" className="px-4 py-3" borderRadius="16px"></Skeleton>
                    </div>
                  </div>
                  <div className="lg:col-span-2">
                    <div className="flex flex-col gap-1">
                      <Skeleton width="10rem"></Skeleton>
                      <Skeleton height="2rem" className="px-4 py-3" borderRadius="16px"></Skeleton>
                    </div>
                  </div>
                  <div className="lg:col-span-3">
                    <div className="flex flex-col gap-1">
                      <Skeleton width="10rem"></Skeleton>
                      <Skeleton height="2rem" className="px-4 py-3" borderRadius="16px"></Skeleton>
                    </div>
                  </div>
                  <div className="lg:col-span-1">
                    <div className="flex flex-col gap-1">
                      <Skeleton width="10rem"></Skeleton>
                      <Skeleton height="2rem" className="px-4 py-3" borderRadius="16px"></Skeleton>
                    </div>
                  </div>
                  <div className="lg:col-span-1">
                    <div className="flex flex-col gap-1">
                      <Skeleton width="10rem"></Skeleton>
                      <Skeleton height="2rem" className="px-4 py-3" borderRadius="16px"></Skeleton>
                    </div>
                  </div>
                  <div className="lg:col-span-1">
                    <div className="flex flex-col gap-1">
                      <Skeleton width="10rem"></Skeleton>
                      <Skeleton height="2rem" className="px-4 py-3" borderRadius="16px"></Skeleton>
                    </div>
                  </div>
                  <div className="lg:col-span-1">
                    <div className="flex flex-col gap-1">
                      <Skeleton width="10rem"></Skeleton>
                      <Skeleton height="2rem" className="px-4 py-3" borderRadius="16px"></Skeleton>
                    </div>
                  </div>
                  <div className="lg:col-span-1">
                    <div className="flex flex-col gap-1">
                      <Skeleton width="10rem"></Skeleton>
                      <Skeleton height="2rem" className="px-4 py-3" borderRadius="16px"></Skeleton>
                    </div>
                  </div>
                </>
              )
            }
          </div>
        </Dialog>
      );
      // break;
    case 'withdraw':
      const handleDataChange = (target, value) => {
        if(target === "points_to_withdraw"){
          // check value limit to 5000
          if(value < 3000){
            value = 3000;
          }
        }
        setData((prevData) => ({
          ...prevData,
          [target]: value
        }));
      }

      var secondsLeft = 300;
      const triggerTimer = () => {
        setOtpButton(false);
        therest.get('/otp/generate', apiToken).then(response => {
          if(response.status){
            setTimer(setInterval(() => {
              setSeconds(secondsLeft);
              secondsLeft -= 1;
              if (secondsLeft < 0) {
                clearInterval(timer);
                setTimer(null);
                setOtpButton(true);
              }
            }, 1000));
          } else {
            setOtpButton(true);
          }
        })
      }

      const beforeWithdrawOnHide = () => {
        setOtpButton(true);
        setSeconds('');
        clearInterval(timer);
        setTimer(null);
        onHide();
      }
      return(
      <>
        <Dialog header="Earnings Withdrawal" draggable={false} visible={open} className="lg:w-1/4 w-3/4" baseZIndex={999999} closable={true} dismissableMask={true} resizable={false} onHide={() => beforeWithdrawOnHide()}>
          <div className='grid grid-cols-3 gap-4'>
            <div className="col-span-3">
              <div className="flex flex-col gap-1">
                <label className="font-bold" htmlFor="name">Points to Withdraw</label>
                <InputNumber min={3000} value={data.points_to_withdraw} showButtons onChange={(e) => handleDataChange('points_to_withdraw', e.value)} inputClassName="w-full ring-0 border rounded-full px-4 py-3" inputId="currency-php" mode="currency" currency="PHP" locale="en-PH" />
              </div>
            </div>
            <div className="col-span-3">
              <div className="flex flex-col gap-1">
                <label className="font-bold" htmlFor="withdrawal_method">Withdrawal Method</label>
                <Dropdown value={(accounts && accounts.find(d => d.code === data.withdrawal_method) || '')} onChange={(e) => handleDataChange('withdrawal_method', e.value.code) } className="w-full ring-0 border rounded-full px-4" options={accounts} optionLabel="name" placeholder="Select Withdraw Method" />
              </div> 
            </div>
            <div className="lg:col-span-1 col-span-3">
              <div className="flex flex-col gap-1">
                <label className="font-bold" htmlFor="withdrawal_method">One Time PIN</label>
                <InputText className="w-full ring-0 border rounded-full px-4 py-3" value={data.otp} onChange={(e) => handleDataChange('otp', e.target.value)} keyfilter="int" />
              </div> 
            </div>
            <div className="lg:col-span-2 col-span-3">
              <div className="flex flex-col gap-1">
                <label>&nbsp;</label>
                <Button disabled={!otpButton} onClick={triggerTimer} label={`Get OTP ${!otpButton && seconds != '' ? '('+seconds+'s)' : ''}`} className="px-4 py-3 rounded-full bg-BrandBlue text-white" />
              </div> 
            </div>

            <div className="col-span-3">
              <div className="border rounded-lg p-4">
                <div className="flex justify-between">
                  <p>Withdrawal Amount</p>
                  <p>{Number(data.points_to_withdraw).toFixed(2)}</p>
                </div>
                <div className="flex justify-between">
                  <p>Less: Admin Fee</p>
                  <p>50.00</p>
                </div>
                <div className="flex justify-between">
                  <p>Total Amount to Receive</p>
                  <p><b>{Number(data.points_to_withdraw - 50).toFixed(2)}</b></p>
                </div>
              </div>
            </div>

            <div className="col-span-3">
              <Button onClick={onSubmit} label="Submit" className="w-full px-4 py-3 rounded-full bg-BrandGreen text-white" />
            </div>
          </div>
        </Dialog>  
      </>);
    default:
      return(<></>);
  }
  
}

export default Modals;