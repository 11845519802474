import React, { useContext, useEffect, useRef, useState } from "react";
import BreadCrumb from "../shared/BreadCrumb"; 
import Select from 'react-select';
import DatePicker from "react-multi-date-picker";
import { AuthContext } from "../../AuthContext";
import therest from "../../therest";
import moment from "moment";
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from "primereact/button";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
const Transactions = () => {
  const { apiToken, apiHeader } = useContext(AuthContext);

  const [date, setDate] = useState(new Date);

  const [selectedTransactionType, setTransactionType] = useState(null);
  // const transactionTypes = [
  //   { name: 'All', code: '0' },
  //   { name: 'Payment', code: '1' },
  //   { name: 'Commissions', code: '2' },
  //   { name: 'Withdrawal (New)', code: '3' },
  //   { name: 'Withdrawal (Inprogress)', code: '4' },
  //   { name: 'Withdrawal (Released)', code: '5' },
  //   { name: 'Points Purchase', code: '6' },
  //   { name: 'Refund', code: '9' }
  // ];
  const transactionTypes = [
    { name: 'All', code: '' },
    { name: 'Payment', code: '1' },
    { name: 'Commission', code: '2' },
    { name: 'Withdrawal', code: '3' },
    { name: 'Points Purchase', code: '4' },
    { name: 'Returns', code: '5' },
  ];
  
  const [transactions, setTransactions] = useState();
  const [filteredTransactions, setFilteredTransactions] = useState();
  const getTransactions = () => {
    therest.get('/transactions/get', apiToken, apiHeader).then((response) => {
      console.log(response);
      setTransactions(response.transactions);
      setFilteredTransactions(response.transactions);
    });
  }

  const renderPaymentMethod =(rowData)=>{
    var payment_method;
    // 1-cash,2-bank transfer,3-ewallet,4-payment center,5-other
    if(rowData.payment_method==1){
      return "Cash";
    }else if(rowData.payment_method==2){
      return "Bank Transfer";
    }else if(rowData.payment_method==3){
      return "eWallet";
    }else if(rowData.payment_method==4){
      return "Payment Center";
    }else if(rowData.payment_method==6){
      return "Points";
    }else{
      return "Other"; // 5
    }
  }

  const renderStatus =(rowData)=>{
    if(rowData.trans_type==2){//if transaction is commission distribution
      if(rowData.cleared==1){
        return "Complete";
      }else{
        return "Pending";
      }
    }else{
    
      switch(rowData.status){
        case 1:
          return "Complete";
          
        case 0:
          return "Pending";
     
        case 5:
          return "Cancelled";
        
        case 2:
          return 'In Progress';
        case 3:
          return 'Processing';
        case 4:
          return 'Released';
        default:
          return "Pending";
          
      }
    }

    
  }

  const renderTransType =(rowData)=>{
    //1-payment,2-withdrawal,3-returns
    if(rowData.trans_type==1){
      return "Payment";
    }else if(rowData.trans_type==2){
      return "Commission";
    }else if(rowData.trans_type==3){
      return "Withdrawal";
    }else if(rowData.trans_type==4){
      return "Points Purchase";
    }else{
      return "Returns";
    }
  }
  const formatDateTime = (rowData) => {
    return moment(rowData.created_at).format('MMMM DD, YYYY hh:mm A');
  }

  useEffect(() => {
    getTransactions();
  }, []);

  const dt = useRef(null); 

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });

  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const header = (
    <div className="flex justify-between -mx-4">
      <span className="p-input-icon-left flex items-center">
        <i className="pi pi-search" />
        <InputText className="w-full ring-0 border rounded-full pl-10 pr-4 py-3 font-normal" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
      </span>
      <Tooltip target=".trans-list" />
      <Button className='trans-list bg-BrandBlue text-white px-4 py-2' type="button" icon="pi pi-download" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
    </div>
  );
  
  const handleDateRangeFilterOnChange = (e) => {
    setDate(e.value);
    const start_date = moment(e.value[0]).format('YYYY-MM-DD 00:00:00');
    const end_date = moment(e.value[1]).format('YYYY-MM-DD 23:59:59');
    if(e.value[0] && e.value[1]){
      setFilteredTransactions(transactions.filter((e) => e.created_at >= start_date && e.created_at <= end_date));
    }
  }

  const handleTransactionTypeFilterOnChange = (e) => {
    setTransactionType(e.value)
    if(e.value.code == ''){
      setFilteredTransactions(transactions); // all
    } else {
      setFilteredTransactions(transactions.filter(t => t.trans_type == e.value.code));
    }
  }

  return(
    <>
      <BreadCrumb links={[{title: 'Transactions', link: '/transactions'}]}/>
      <div className="w-full min-h-[50%] relative bg-white rounded-lg mt-8">
        <div className="w-full gap-4 flex items-center lg:p-8 p-4">
          <h1 className="lg:text-4xl text-xl font-bold text-sky-600">Transactions</h1>
        </div>
        <div className="w-full pt-0 lg:px-8 px-4">
          <div className="grid lg:grid-cols-5 lg:gap-8 gap-4">
            <div className="flex flex-col col-span-2">
              <Dropdown value={selectedTransactionType} onChange={handleTransactionTypeFilterOnChange} options={transactionTypes} optionLabel="name" 
                placeholder="Transaction Type" className="w-full border rounded-full"
              />
            </div>
            <div className="flex flex-col col-span-2 justify-center">
              <Calendar className="border rounded-full px-4 py-3" value={date} onChange={handleDateRangeFilterOnChange} placeholder="Select Date" dateFormat="M d, yy" showIcon selectionMode="range" readOnlyInput hideOnRangeSelection={true}/>
            </div>
          </div>
        </div>
        <div className='w-full p-4 lg:p-8'>
          <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div className="">
              <DataTable ref={dt} header={header} filters={filters} className="bg-transparent" value={filteredTransactions} size={'medium'} paginator rows={10} rowsPerPageOptions={[10, 50, 100]} tableStyle={{ minWidth: '50rem' }} stripedRows>
                <Column headerClassName="border-b" field="transaction_id" sortable header="Transaction ID" />
                <Column headerClassName="border-b" field="payment_method" sortable body={renderPaymentMethod} header="Payment Method" />
                <Column headerClassName="border-b" field="trans_type" sortable body={renderTransType} header="Transaction Type" />
                <Column headerClassName="border-b" field="description" sortable header="Description" />
                <Column headerClassName="border-b" field="product.name" sortable header="Product" />
                <Column headerClassName="border-b" field="product.category.name" sortable header="Category" />
                <Column headerClassName="border-b" field="user.name" sortable header="For User" />
                <Column headerClassName="border-b" field="processed_by.name" sortable header="Created By"/>
                <Column headerClassName="border-b" field="created_at" sortable body={formatDateTime} header="Date/Time" />
                <Column headerClassName="border-b" field="status" sortable header="Status" body={renderStatus}/>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Transactions;