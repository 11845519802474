const apiUrl = process.env.REACT_APP_API_URL;

const sendRequest = async (url, method, token, data, header = {}) => {
  const options = {
    method,
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data ? JSON.stringify(data) : undefined,
  };

  if (token) {
    options.headers['Authorization'] =  "Bearer " + token;
  }

  try {
    const response = await fetch(apiUrl + url, options);
    if (!response.ok) {
      console.error(response);
    }
    return await response.json();
  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
    // throw error;
  }
};

const therest = {
  get: (url, token, headers = {}) => {
    const hasParams = url.includes('?');
    const queryString = Object.entries(headers)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');
    const fullUrl = hasParams ? `${url}&${queryString}` : `${url}?${queryString}`;
    return sendRequest(fullUrl, 'GET', token, null, headers);
  },

  // get: (url, token, headers = {}) => {
  //   const queryString = objectToQueryString(headers);
  //   const fullUrl = queryString ? `${url}?${queryString}` : url;
  //   return sendRequest(fullUrl, 'GET', token, null, headers);
  // },

  // get: (url, token, header) => sendRequest(url, 'GET', token, null, header),

  post: (url, data, token) => sendRequest(url, 'POST', token, data),

  put: (url, data, token) => sendRequest(url, 'PUT', token, data),

  delete: (url, token) => sendRequest(url, 'DELETE', token),
};

export default therest;